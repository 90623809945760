import React, { useState } from 'react'
import CardCart from 'src/components/card/cardCart'
import { useAppSelector } from 'src/store'

export default function Cart() {
    const look = useAppSelector((state) => state.look.current)
    const company = useAppSelector((state: State.Root) => state.profile?.company)

    const [sizes, setSizes] = useState({})

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const garmentTypes = company.garment_types
        ? company.garment_types
        : ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']

    const handleSizeChange = (type, value) => {
        setSizes({ ...sizes, [type]: value })
    }
    return (
        <div style={{ width: '100%' }}>
            {garmentTypes.map(
                (type) =>
                    look[type.toLowerCase()] && (
                        <CardCart
                            key={type}
                            garment={look[type.toLowerCase()]}
                            ratio={garmentImgRatio}
                            onChange={(value) => handleSizeChange(type, value)}
                            value={sizes[type] || null}
                            hideDescription
                        />
                    )
            )}
        </div>
    )
}
