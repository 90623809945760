import { inIframe, sendIframeMessage, sendIframeMessageWithResponse } from 'src/utils/iframe'
import { getQueryValue } from 'src/utils/query'
import store from 'src/store'

export const getGarmentSizeLabel = (
    value: string,
    optionSizes: { label: string; value: string }[]
) => {
    for (const optionSize of optionSizes) {
        if (optionSize.value == value) {
            return optionSize.label
        }
    }
    return null
}

export const getGarmentSizeLabelValue = (
    size: Models.GarmentSize,
    modelGarmentSizes: string[] = null,
    modelName: string = null
) => {
    const label = typeof size.label === 'string' ? size.label.toUpperCase() : size.label
    const labelValue = { label: label, value: size.value }
    if (typeof size.in_stock !== 'undefined') {
        labelValue['in_stock'] = size.in_stock
    }
    if (modelGarmentSizes && modelGarmentSizes.indexOf(label) !== -1) {
        labelValue['extra'] = ['product.worn_by', { modelName }]
    }
    return labelValue
}

export const getGarmentOptionSizes = (garment: Models.Garment) => {
    let modelGarmentSizes = null
    const model = store.getState().look.current?.model
    if (model) {
        const modelGarmentSizesField = `model_${garment.garment_type.toLowerCase()}_garment_sizes`
        if (model[modelGarmentSizesField]) {
            modelGarmentSizes = model[modelGarmentSizesField]
        } else if (model['model_garment_sizes']) {
            modelGarmentSizes = model['model_garment_sizes']
        } else if (model['model_garment_size']) {
            modelGarmentSizes = [model['model_garment_size']]
        }
    }
    return garment.product_sizes.map((size) =>
        getGarmentSizeLabelValue(size, modelGarmentSizes, model?.model_name)
    )
}

export const addGarmentSizesToCart = (params: any[], callback: (success: boolean) => void) => {
    // ---- Debug ----
    for (const one of params) {
        console.log(one.garment.garment_id, one.currentSize)
    }

    // ---- En fonction du client ----
    if (inIframe() && getQueryValue('domain') == 'marinehenrion.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let variation_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.variation_id !== 'undefined') {
                    variation_id = size.variation_id
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: 'https://marinehenrion.com',
                    data: {
                        quantity: 1,
                        'add-to-cart': garment.product_external_id,
                        product_id: garment.product_external_id,
                        variation_id: variation_id,
                    },
                },
            })
        }

        // ---- Refresh du cart ----
        payloads.push({
            type: 'javascript',
            payload: `jQuery(document.body).trigger('wc_fragment_refresh');`,
        })

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                return callback(true)
            },
            params.length * 10000
        )
    }
    // ---- Operation de noel ----
    else if (getQueryValue('domain') == 'christmas.com') {
        // ---- Ouverture de lien externe si possible ----
        if (params.length != 1) {
            alert(
                `This is a demo experience. Get in touch with Veesual to implement the cart! We wish you a merry Christmas.`
            )
        } else {
            alert(
                `This is a demo experience. Get in touch with Veesual to implement the cart! We wish you a merry Christmas.`
            )
            window.open(params[0].garment.product_external_url, '_blank')
        }

        // ---- Callback ----
        callback(true)
    }
    // ---- La redoute ----
    else if (
        inIframe() &&
        ['laredoute.fr', 'laredoute.es', 'laredoute.co.uk'].indexOf(getQueryValue('domain')) !== -1
    ) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null,
                basket_size = null,
                pres_code = null,
                category_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id || '-'
                    basket_size = size.basket_size || ''
                    pres_code = size.pres_code || ''
                    category_id = size.category_id || ''
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: '/shoppingbasket/additemtocart',
                    data: {
                        quant: '1',
                        basketSize: basket_size,
                        presCode: pres_code,
                        itemOfferId: basket_size ? '-' : offer_id,
                        contextCategoryId: category_id,
                        productId: garment.product_external_id,
                        loyalty: false,
                        loyaltySource: 'pdp',
                        origin: 'treestructureflyout',
                    },
                },
            })
        }

        // ---- Ajout au payloads ----
        payloads.push({
            type: 'request',
            payload: {
                method: 'GET',
                url: '/shoppingbasket/basketitemcount/?_=' + Date.now(),
            },
        })

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const itemCount = parseInt(responses.pop())
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `jQuery('#basketIcon .badge').text(${itemCount}).css('display', 'flex');`,
                    },
                ])
                return callback(true)
            },
            params.length * 5000
        )
    }
    // ---- Sustainabl ----
    else if (inIframe() && getQueryValue('domain') == 'sustainabl.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let variant_id = null,
                variant_color = null,
                variant_size = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.variant_id !== 'undefined') {
                    variant_id = size.variant_id
                    variant_color = size.variant_color
                    variant_size = size.variant_size
                }
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: '/cart/add',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/javascript',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    dataType: 'json',
                    data: {
                        'options[Size]': variant_size,
                        Color: variant_color,
                        quantity: '1',
                        form_type: 'product',
                        utf8: '✓',
                        id: String(variant_id),
                        sections: ['cart-icon-bubble'],
                        sections_url: '/pages/mix-and-match',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                let escapedHtml = JSON.parse(response)
                if (!escapedHtml.sections || !escapedHtml.sections['cart-icon-bubble']) {
                    return callback(false)
                }
                escapedHtml = JSON.stringify(String(escapedHtml.sections['cart-icon-bubble']))
                escapedHtml = escapedHtml.substring(1, escapedHtml.length - 1)
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `
                            (function(){
                                const tmpSectionParser = document.createElement('body');
                                tmpSectionParser.innerHTML = "${escapedHtml}";
                                document.getElementById('cart-icon-bubble').innerHTML = tmpSectionParser.querySelector('#shopify-section-cart-icon-bubble').innerHTML;
                            })();
                        `,
                    },
                ])
                return callback(true)
            },
            params.length * 5000
        )
    }
    // ---- Sustainabl ----
    else if (inIframe() && getQueryValue('domain') == 'jules.com') {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id
                }
            }

            // ---- Url en fonction de l'origin ----
            let postUrl = '/on/demandware.store/Sites-Jules-SFRA-Site/fr_FR/Cart-AddProduct'
            const origin = getQueryValue('origin', '')
            if (origin.match(/\/nl-be\//)) {
                postUrl = '/on/demandware.store/Sites-Jules-BE-SFRA-Site/nl_BE/Cart-AddProduct'
            } else if (origin.match(/\/fr-be\//)) {
                postUrl = '/on/demandware.store/Sites-Jules-BE-SFRA-Site/fr_BE/Cart-AddProduct'
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    data: {
                        pid: offer_id,
                        quantity: '1',
                        options: '[]',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        sendIframeMessageWithResponse(
            'veesual_execute',
            payloads,
            (responses) => {
                if (responses === false) {
                    return callback(false)
                }
                const response = responses.pop()
                const parsedResponse = JSON.parse(response)
                if (
                    !parsedResponse ||
                    parsedResponse.error ||
                    !parsedResponse.cart ||
                    !parsedResponse.cart.numItems
                ) {
                    return callback(false)
                }
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}');`,
                    },
                ])
                return callback(true)
            },
            params.length * 10000
        )
    }
    // ---- Default ----
    else {
        setTimeout(() => callback(true), 2000)
    }
}

export const addGarmentSizeToCart = (
    garment: Models.Garment,
    currentSize: string,
    callback: (success: boolean) => void
) => {
    addGarmentSizesToCart([{ garment, currentSize }], callback)
}
