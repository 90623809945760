import { Dispatch } from 'redux'
import { GetCompanyProfileService } from '../../services/profile'
import { SetError } from './error'

import { FETCH_COMPANY_PROFILE, SET_HEADER_HIDDEN, SET_PARENT_HEIGHT } from './index'

export const FetchCompanyProfileAction: Types.ActionFactory<State.Profile> = (payload) => ({
    type: FETCH_COMPANY_PROFILE,
    payload,
})

export const SetParentHeightAction: Types.ActionFactory<number> = (payload) => ({
    type: SET_PARENT_HEIGHT,
    payload,
})

export const SetHeaderHiddenAction: Types.ActionFactory<boolean> = (payload) => ({
    type: SET_HEADER_HIDDEN,
    payload,
})

export const GetCompanyProfileCall = () => async (dispatch: Dispatch<Types.Action>) => {
    const res: any = await GetCompanyProfileService()
    if ([200, 201, 204].includes(res?.status)) {
        dispatch(FetchCompanyProfileAction(res.data))
        return true
    }
    dispatch(SetError({ message: res?.data.message || res?.data.title }))
    return false
}
