import ApiInstance from './index'

import { getQueryDefaultParams } from 'src/utils/query'

const LOOK_ENDPOINT = `/look`
const LOOK_RECOMMENDATIONS_ENDPOINT = `/look/recommendations`

// GET LOOK

const GetLookService = async (params: API.Req.GetLook): Promise<API.Resp.GetLook> => {
    try {
        return await ApiInstance.get(`${LOOK_ENDPOINT}`, {
            params: Object.assign(params, getQueryDefaultParams()),
        })
    } catch (error) {
        return error.response
    }
}

const GetLookRecommandationsService = async (
    params: API.Req.GetLook
): Promise<API.Resp.GetLookRecommendations> => {
    try {
        return await ApiInstance.get(`${LOOK_RECOMMENDATIONS_ENDPOINT}`, {
            params: Object.assign(params, getQueryDefaultParams()),
        })
    } catch (error) {
        return error.response
    }
}

export { GetLookService, GetLookRecommandationsService }
