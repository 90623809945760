import React from 'react'
import Cart from '../Cart'

interface LayoutProps {
    children: React.ReactNode
}

const LayoutContainer: React.FunctionComponent = (props: LayoutProps) => {
    return (
        <div className='layout--container'>
            <div className='layout--main'>{props.children}</div>
            <div className='layout--optional'>
                <Cart />
            </div>
        </div>
    )
}

export default LayoutContainer
