export const inIframe = () => {
    return window.self !== window.top
}

export const sendIframeMessage = (type: string, data: any) => {
    const message = type + ':' + JSON.stringify(data)
    window.parent.postMessage(message, '*')
}

export const sendIframeMessageWithResponse = (
    type: string,
    data: any,
    callback = null,
    timeout = 0
) => {
    // ---- Initialisation du timeout ----
    let timeoutRef = null

    // ---- Pour la reception de la reponse ----
    const response = Date.now()
    const receiveResponse = (event) => {
        try {
            if (typeof event.data !== 'string') {
                return
            }

            const splitted = event.data.split(':')
            if (splitted[0] === `veesual_response_${response}`) {
                // ---- Suppression du listener ----
                clearTimeout(timeoutRef)
                window.removeEventListener('message', receiveResponse)

                // ---- Appel du callback ----
                if (callback !== null) {
                    callback(JSON.parse(splitted.slice(1).join(':')))
                }
            }
        } catch (e) {
            console.log('sendIframeMessageWithResponse exception')
        }
    }
    window.addEventListener('message', receiveResponse, false)

    // ---- Lancement du timeout si besoin ----
    if (timeout > 0) {
        timeoutRef = setTimeout(() => {
            // ---- On arrete tout ----
            window.removeEventListener('message', receiveResponse)
            if (callback !== null) {
                callback(false)
            }
        }, timeout)
    }

    // ---- Envoi du message ----
    const message = type + ':' + JSON.stringify({ response, data })
    window.parent.postMessage(message, '*')
}
