import {
    SET_QUERY_GARMENT_TYPE,
    FETCH_ALL_GARMENTS,
    FETCH_TYPE_GARMENT,
    FETCH_GARMENT_DETAIL,
    SET_CART_ADDED,
} from '../actions'

export const initialState: State.Garment = {
    queryType: null,
    type: null,
    all: null,
    detail: null,
    cartAdded: null,
}

const GarmentReducers = (
    state: State.Garment = initialState,
    action: Types.Action
): State.Garment => {
    switch (action.type) {
        case SET_QUERY_GARMENT_TYPE:
            return {
                ...state,
                queryType: action.payload,
            }
        case FETCH_ALL_GARMENTS:
            return {
                ...state,
                all: action.payload,
            }
        case FETCH_TYPE_GARMENT:
            return {
                ...state,
                type: action.payload,
                queryType: action.payload,
            }
        case FETCH_GARMENT_DETAIL:
            return {
                ...state,
                detail: action.payload,
            }
        case SET_CART_ADDED:
            return {
                ...state,
                cartAdded: action.payload,
            }
        default:
            return state
    }
}

export default GarmentReducers
