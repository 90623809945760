import { Dispatch } from 'redux'
import { getQueryGender } from 'src/utils/query'
import { GetAllModelsService } from '../../services/modelProduct'

import { FETCH_ALL_MODELS } from './index'

export const FetchAllModelAction: Types.ActionFactory<State.ModelProduct> = (payload) => ({
    type: FETCH_ALL_MODELS,
    payload,
})

export const GetAllModelsCall =
    () => async (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
        const genderFilterValue: string = getQueryGender(getState().profile.company?.genders)
        const seed = getState().profile.seed
        const res: any = await GetAllModelsService({
            ...(genderFilterValue && ({ gender: genderFilterValue } as any)),
            seed,
        })
        if ([200, 201, 204].includes(res.status)) {
            dispatch(FetchAllModelAction(res.data))
            return true
        }
        return false
    }
