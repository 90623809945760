import { HANDLE_FILTERS_MODEL, HANDLE_SHOW_FILTERS } from './index'

export const ChangeFiltersModelAction: Types.ActionFactory<State.Filters> = (payload) => ({
    type: HANDLE_FILTERS_MODEL,
    payload,
})

export const ChangeShowFiltersAction: Types.ActionFactory<boolean> = (payload) => ({
    type: HANDLE_SHOW_FILTERS,
    payload,
})
