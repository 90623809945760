import ApiInstance from './index'

import { getQueryDefaultParams, queryParamSerializer } from 'src/utils/query'

const MODELS_ENDPOINT = `/models`

// GET ALL GARMENTS

const GetAllModelsService = async (
    params: API.Req.GetModelsProduct
): Promise<API.Resp.GetModelsProduct> => {
    try {
        return await ApiInstance.get(`${MODELS_ENDPOINT}`, {
            params: params
                ? Object.assign(params, getQueryDefaultParams())
                : getQueryDefaultParams(),
            paramsSerializer: queryParamSerializer,
        })
    } catch (error) {
        return error.response
    }
}

export { GetAllModelsService }
