import { SET_ERROR } from '../actions'

export const initialState: State.Error = {
    message: null,
}

const ErrorReducer = (state: State.Error = initialState, action: Types.Action): State.Error => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                message: action.payload.message,
            }
        default:
            return state
    }
}

export default ErrorReducer
