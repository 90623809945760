import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'src/store'
import { SetGarmentHistory } from 'src/store/actions/look'
import {
    getAllGarments,
    getAllGarmentsForType,
    getCurrentPageForType,
} from 'src/store/slices/databaseSlice'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import useCustomTranslation from 'src/utils/translation'
import CardItem from './card/cardItem'
import Loader from './Loader'

export default function Catalog() {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()

    const garmentType = useAppSelector((state) => state.garment?.type)
    const allGarmentTypes = useAppSelector((state) => state.profile.company.garment_types)
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const allGarmentsForType = useAppSelector((state) => getAllGarmentsForType(state, garmentType))
    const look = useAppSelector((state) => state.look?.request)
    const garmentsHistory = useAppSelector((state) => state.look.garmentsHistory)
    const company = useAppSelector((state) => state.profile?.company)
    const currentPage = useAppSelector((state) => getCurrentPageForType(state, garmentType))

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const [getGarmentsTrigger, { isLoading, error }] = useCustomGetGarments()

    useEffect(() => {
        if (!garmentType || !look || isLoading || error) {
            return
        }

        // We check if we have data for each garment types
        allGarmentTypes.forEach((localType) => {
            if (
                !allGarments[localType] ||
                (look[localType.toLowerCase()] &&
                    !allGarments[localType].all.find(
                        (garment) => garment.garment_id === look[localType.toLowerCase()].garment_id
                    ))
            ) {
                getGarmentsTrigger({
                    garment_id: look[localType.toLowerCase()]?.garment_id,
                    type: localType,
                    page: 1,
                })
                    .unwrap()
                    .then((resp) => {
                        // If we don't have history data yet we populate it with the first garment
                        if (!garmentsHistory[localType] && resp && resp.items.length > 0) {
                            dispatch(SetGarmentHistory({ type: localType, garment: resp.items[0] }))
                        }
                    })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentType, look])

    const handleOnScroll = async (e: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = e.currentTarget.clientHeight
        const { scrollHeight } = e.currentTarget

        const { scrollTop } = e.currentTarget
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100

        const lastQueryResult = allGarments[garmentType]?.current

        if (percentageScroll < 70 || isLoading || !lastQueryResult) {
            return
        }

        // Prevent useless call
        if (
            lastQueryResult &&
            allGarments[garmentType].all.length <=
                (currentPage - 1) * lastQueryResult.num_items_per_page
        ) {
            return
        }

        // Prevent loading 1 page that doesn't exist
        if (
            lastQueryResult.current_page_number * lastQueryResult.num_items_per_page >=
            lastQueryResult.total_count
        ) {
            return
        }
        getGarmentsTrigger({
            ...(allGarments[garmentType].filterGarmentId
                ? { garment_id: allGarments[garmentType].filterGarmentId }
                : null),
            type: garmentType,
            page: currentPage + 1,
        })
    }

    if (!allGarmentsForType || company.garment_types.indexOf(garmentType) === -1) {
        return <div></div>
    }

    if (allGarmentsForType.length === 0) {
        return (
            <div>
                <div>{t('product.no_products')}</div>
            </div>
        )
    }

    return (
        <div className='catalog--container' onScroll={handleOnScroll}>
            <Row justify='start'>
                {allGarmentsForType.map((item: Models.Garment) => (
                    <Col
                        span={12}
                        xs={{ span: 12 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                        key={item.garment_id}
                    >
                        <CardItem data={item} ratio={garmentImgRatio} />
                    </Col>
                ))}
                {isLoading && (
                    <Col
                        span={12}
                        xs={{ span: 12 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                    >
                        <Loader />
                    </Col>
                )}
            </Row>
        </div>
    )
}
