import ApiInstance from './index'

const EVENTS_ENDPOINT = `/event`

// Post an init event
const PostInitEvent = async (params: any): Promise<any> => {
    try {
        return await ApiInstance.post(`${EVENTS_ENDPOINT}`, params)
    } catch (error) {
        return error.response
    }
}

export { PostInitEvent }
