import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import React, { useRef } from 'react'
import { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'
import ImageSmooth from './ImageSmooth'

interface VerticalCarouslProps {
    onChangeLookClick?(): void
    onLookClick?(newIndex: number): void
}

export default function VerticalCarousel(props: VerticalCarouslProps) {
    const { t } = useCustomTranslation()

    const { onChangeLookClick, onLookClick } = props

    const look = useAppSelector((state) => state.look.current)
    const company = useAppSelector((state) => state.profile?.company)
    const lookImgRatio = company?.look_image_ratio

    const scrollRef = useRef<HTMLDivElement>(null)

    const handleScrollUp = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop -= scrollRef.current.clientHeight
        }
    }

    const handleScrollDown = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop += scrollRef.current.clientHeight
        }
    }

    const handleLookClick = (index: number) => {
        if (onLookClick) {
            onLookClick(index)
        }
    }

    const handleChangeLookClick = () => {
        if (onChangeLookClick) {
            onChangeLookClick()
        }
    }

    return (
        <Col className='vertical-carousel--container'>
            <div>{t('sandro.porte')}</div>
            <Button
                onClick={handleScrollUp}
                type='text'
                className='button--icon'
                icon={<UpOutlined />}
                size='large'
            />
            <Col className='vertical-carousel--scroll-container' ref={scrollRef}>
                {look.image_urls.map((imageUrl, index) => (
                    <Row
                        className='vertical-carousel--image-container'
                        key={imageUrl}
                        onClick={() => handleLookClick(index)}
                    >
                        <ImageSmooth src={imageUrl} ratio={lookImgRatio} loader={true} />
                    </Row>
                ))}
            </Col>
            <Button
                onClick={handleScrollDown}
                type='text'
                className='button--icon'
                icon={<DownOutlined />}
                size='large'
            />
            <Button type='link' className='button--link' onClick={handleChangeLookClick}>
                {t('sandro.change_look')}
            </Button>
        </Col>
    )
}
