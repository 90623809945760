import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Drawer, Row } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Catalog from 'src/components/Catalog'
import LookImage from 'src/components/LookImage'
import MoreLook from 'src/components/MoreLook'
import VerticalCarousel from 'src/components/VerticalCarousel'
import Cart from 'src/containers/Cart'
import { useAppSelector } from 'src/store'
import { HandleLookRequest, SetLookIndexAction } from 'src/store/actions/look'
import useCustomTranslation from 'src/utils/translation'

export default function Home() {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const lookRequest = useAppSelector((state) => state.look.request)
    const garmentType = useAppSelector((state) => state.garment?.type)
    const [drawerType, setDrawerType] = useState<string | null>(null)

    const openCatalog = () => {
        setDrawerType('catalog')
    }

    const openLook = () => {
        setDrawerType('look')
    }

    const openDetail = () => {
        setDrawerType('detail')
    }

    const closeDrawer = () => {
        setDrawerType(null)
    }

    const handleLookSelect = (model: Models.ModelProduct) => {
        dispatch(
            HandleLookRequest({ lookRequest: { ...lookRequest, model: model }, focus: 'MODEL' })
        )
        setDrawerType(null)
    }

    const onLookClick = (newIndex: number) => {
        dispatch(SetLookIndexAction(newIndex))
    }

    return (
        <div className='home--container'>
            <div className='home--vertical-carousel'>
                <VerticalCarousel onChangeLookClick={openLook} onLookClick={onLookClick} />
            </div>
            <div className='home--look-container'>
                <LookImage />
            </div>
            <ArrowLeftOutlined style={{ position: 'absolute', top: 16, left: 16, fontSize: 24 }} />
            <Row className='home--bottom-container'>
                <div>
                    <Button
                        type='default'
                        className='button--dark button--mobile'
                        onClick={openDetail}
                    >
                        {t('sandro.see_look_products')}
                    </Button>
                </div>
                <Button type='default' className='button--default' onClick={openCatalog}>
                    {t('sandro.other_bags')}
                </Button>
            </Row>
            <Drawer
                placement='bottom'
                onClose={closeDrawer}
                destroyOnClose
                getContainer={false}
                open={drawerType === 'catalog'}
                title={garmentType}
                height={'75%'}
            >
                <Catalog />
            </Drawer>
            <Drawer
                placement='bottom'
                onClose={closeDrawer}
                destroyOnClose
                getContainer={false}
                open={drawerType === 'look'}
                height={'35%'}
                title={t('sandro.change_look')}
            >
                <MoreLook onLookSelect={handleLookSelect} />
            </Drawer>
            <Drawer
                placement='bottom'
                onClose={closeDrawer}
                destroyOnClose
                getContainer={false}
                open={drawerType === 'detail'}
                title={t('sandro.look_products')}
                height={'75%'}
            >
                <Cart />
            </Drawer>
        </div>
    )
}
