import {
    FETCH_ALL_TOP_GARMENTS,
    FETCH_ALL_BOTTOM_GARMENTS,
    FETCH_ALL_DRESS_GARMENTS,
    FETCH_ALL_OUTERWEAR_GARMENTS,
} from '../actions'

export const initialState: State.Swipe = {
    allTop: null,
    allBottom: null,
    allDress: null,
    allOuterwear: null,
}

const SwipeReducers = (state: State.Swipe = initialState, action: Types.Action): State.Swipe => {
    switch (action.type) {
        case FETCH_ALL_TOP_GARMENTS:
            return {
                ...state,
                allTop: action.payload,
            }
        case FETCH_ALL_BOTTOM_GARMENTS:
            return {
                ...state,
                allBottom: action.payload,
            }
        case FETCH_ALL_DRESS_GARMENTS:
            return {
                ...state,
                allDress: action.payload,
            }
        case FETCH_ALL_OUTERWEAR_GARMENTS:
            return {
                ...state,
                allOuterwear: action.payload,
            }
        default:
            return state
    }
}

export default SwipeReducers
