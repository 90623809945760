import React, { useEffect } from 'react'
import useCustomTranslation from 'src/utils/translation'
import { ReactFitty } from 'react-fitty'
import { Select } from 'antd'

const { Option } = Select

interface SelectProps {
    placeholder: string
    options: any
    translation?: boolean
    name: string
    value?: any
    onChange(e: any, name: string): void
    defaultOpen?: boolean
    customStyle?: any
    isFacet?: boolean
}

const FilterSelect = React.forwardRef((props: SelectProps, ref: React.Ref<HTMLSelectElement>) => {
    const { t } = useCustomTranslation()
    const {
        options,
        translation = true,
        placeholder,
        name,
        value,
        defaultOpen = false,
        customStyle = {},
        isFacet,
    } = props

    useEffect(() => {
        if (value) {
            const filteredOptions = options.filter((one) => one.value === value)
            if (filteredOptions.length !== 1) {
                props.onChange(null, name)
            }
        }
        // eslint-disable-next-line
    }, [options, value])

    const getPopupContainer = (trigger) => {
        const closest = trigger.closest('.ant-layout-content')
        return closest || document.body
    }

    const filteredOptions = options.filter((one) => one.value === value)

    const inStock = (option) => {
        if (typeof option.in_stock !== 'undefined') {
            return !!option.in_stock
        }
        return true
    }

    return (
        <>
            <Select
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ref={(selectRef) => (ref = selectRef as unknown as React.Ref<HTMLSelectElement>)}
                placeholder={placeholder ?? ''}
                className='select select--container'
                value={filteredOptions.length === 1 ? value : null}
                onClick={(e: any) => e.stopPropagation()}
                onChange={(e: any) => props.onChange(e, name)}
                defaultOpen={defaultOpen}
                autoFocus={defaultOpen}
                getPopupContainer={getPopupContainer}
                style={customStyle}
                showAction={['focus', 'click']}
            >
                {options.map((option, optionKey: number) => (
                    <Option
                        className='select--option'
                        key={optionKey}
                        value={option.value}
                        disabled={!inStock(option)}
                    >
                        {!isFacet && (
                            <>
                                <div className='select--option-label'>
                                    {translation ? t(option.label) : option.label}
                                </div>

                                {option.extra && (
                                    <div className='select--option-extra'>
                                        <ReactFitty minSize={9} maxSize={12} wrapText={true}>
                                            {Array.isArray(option.extra)
                                                ? t.apply(this, option.extra)
                                                : option.extra}
                                        </ReactFitty>
                                    </div>
                                )}
                            </>
                        )}
                        {option.count && (
                            <div>{`${option.label ? option.label : option.value} (${
                                option.count
                            })`}</div>
                        )}
                    </Option>
                ))}
            </Select>
        </>
    )
})

FilterSelect.displayName = 'FilterSelect'

export default FilterSelect
