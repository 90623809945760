import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { getCommonHeaders } from 'src/settings/global'
import { baseUrl, getQueryValue, queryParamSerializer } from 'src/utils/query'

const apiKey: string = getQueryValue(
    'api_key',
    '5396f7a3918ab76fd8d014765a68bb84cabdad7b764f574cf9db68111e1f8890f22924efdf1ee65ace9b-8e4d384c0f5ee0705e3f98bf66d5c42c'
)

// Basic RTK query function to make API calls
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json')
        headers.set('Accept-Language', 'fr')
        headers.set('Authorization', 'Bearer ' + apiKey)
        return headers
    },
    headers: getCommonHeaders(),
    paramsSerializer: queryParamSerializer,
})
