import { useAppSelector } from 'src/store'
import { useGetGarmentsMutation } from 'src/store/api/api-garment'
import { getCurrentActiveFiltersForType } from 'src/store/slices/databaseSlice'
import { getQueryGender } from './query'

// Custom hook to inject default param inside the getGarmentsMutation
export default function useCustomGetGarments() {
    const companyGenders = useAppSelector((state) => state.profile?.company?.genders)
    const garmentType = useAppSelector((state) => state.garment.type)
    const filters = useAppSelector((state) => getCurrentActiveFiltersForType(state, garmentType))
    const seed = useAppSelector((state) => state.profile?.seed)
    const [getGarmentsTrigger, result] = useGetGarmentsMutation()

    function customGarmentsTrigger(params: API.Req.GetGarments) {
        const genderFilterValue: string = getQueryGender(companyGenders)
        return getGarmentsTrigger({
            ...(genderFilterValue && { gender: genderFilterValue }),
            ...params,
            ...(filters && !params.filter && { filter: filters }),
            ...{ seed },
        })
    }

    return [customGarmentsTrigger, result] as const
}
