import {
    HANDLE_ADD_TO_FAVORITES_OUTFIT,
    HANDLE_REMOVE_TO_FAVORITES_OUTFIT,
    HANDLE_ADD_TO_FAVORITES_ITEMS,
    HANDLE_REMOVE_TO_FAVORITES_ITEMS,
    SWITCH_FAVORITES_CONTENT,
    CHANGE_STORAGE_BASE_KEY,
} from './index'

export const AddFavoritesOutfitAction: Types.ActionFactory<Models.Look[]> = (payload) => ({
    type: HANDLE_ADD_TO_FAVORITES_OUTFIT,
    payload,
})

export const RemoveFavoritesOutfitAction: Types.ActionFactory<Models.Look[]> = (payload) => ({
    type: HANDLE_REMOVE_TO_FAVORITES_OUTFIT,
    payload,
})

export const AddFavoritesItemsAction: Types.ActionFactory<Models.Garment[]> = (payload) => ({
    type: HANDLE_ADD_TO_FAVORITES_ITEMS,
    payload,
})

export const RemoveFavoritesItemsAction: Types.ActionFactory<Models.Garment[]> = (payload) => ({
    type: HANDLE_REMOVE_TO_FAVORITES_ITEMS,
    payload,
})

export const SwitchFavoritesContentAction: Types.ActionFactory<boolean> = (payload) => ({
    type: SWITCH_FAVORITES_CONTENT,
    payload,
})

export const ChangeStorageBaseKey: Types.ActionFactory<string> = (payload) => ({
    type: CHANGE_STORAGE_BASE_KEY,
    payload,
})
