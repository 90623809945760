import { CHANGE_MODAL } from '../actions'

export const initialState: State.Modal = {
    open: false,
}

const ModalReducers = (
    state: State.Modal = initialState,
    action: Types.Action<boolean>
): State.Modal => {
    switch (action.type) {
        case CHANGE_MODAL:
            return {
                open: action.payload,
            }
        default:
            return state
    }
}

export default ModalReducers
