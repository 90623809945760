import { HANDLE_SIDEBAR } from '../actions'

export const initialState: State.Sidebar = {
    open: false,
    page: '',
}

const SidebarReducers = (
    state: State.Sidebar = initialState,
    action: Types.Action
): State.Sidebar => {
    switch (action.type) {
        case HANDLE_SIDEBAR:
            return {
                ...state,
                open: !state.open,
                page: action.payload,
            }
        default:
            return state
    }
}

export default SidebarReducers
