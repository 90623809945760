import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'

import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { trackEvent } from 'src/utils/tracking'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import { HandleLookRequest } from 'src/store/actions/look'
import ImageSmooth from '../ImageSmooth'
import { Card, Typography } from 'antd'

const { Title, Paragraph } = Typography

interface CardItemProps {
    data: Models.Garment
    ratio: number
}

const CardItem: React.FunctionComponent<CardItemProps> = (props) => {
    const dispatch = useDispatch()
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const descriptionCatalog = useSelector(
        (state: State.Root) => state.profile?.company?.description_catalog
    )
    const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)

    const { data, ratio } = props

    const garment = useSelector((state: State.Root) =>
        state.look.request ? state.look.request[data.garment_type.toLowerCase()] : null
    )

    const isSelected = garment?.garment_id === data?.garment_id

    const imageUrl =
        useClipping && data.image_clipping_url ? data.image_clipping_url : data.image_url

    const handleCardClick = () => {
        trackEvent('Item Selected', data, 'Catalog')
        dispatch(
            HandleLookRequest({
                lookRequest: { [data.garment_type.toLowerCase()]: data },
                focus: data.garment_type,
            })
        )
        dispatch(ChangeShowFiltersAction(false))
    }

    return (
        <Card
            className={
                'card card--container override_card_container override_card_catalog_container' +
                (data.product_brand ? ' card--with-brand' : '') +
                (descriptionCatalog === false ? ' card--without-description' : '') +
                (isSelected ? ' card--container--selected' : '')
            }
            onClick={handleCardClick}
            cover={
                <div className='card card--image-container'>
                    <div
                        className={
                            'override_card_cover_catalog ' +
                            (isSelected ? 'card--image card--selected' : 'card--image')
                        }
                    >
                        <ImageSmooth
                            className='card--background'
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            transition={false}
                        />
                    </div>
                </div>
            }
        >
            {data.product_brand && (
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--center text--small card--text'
                >
                    {parse(data.product_brand)}
                </Paragraph>
            )}
            <Title
                ellipsis={{
                    rows: descriptionCatalog === false ? 2 : 1,
                }}
                className='title title--h3 title--center card--title'
            >
                {parse(data.product_name)}
            </Title>
            {descriptionCatalog !== false && (
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--center text--small card--text'
                >
                    {parse(data.product_description)}
                </Paragraph>
            )}
            {!!data.product_price_original && data.product_price_original > data.product_price && (
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='title title--center card--price--promotion'
                >
                    <span className='card--price--original'>
                        {formattedPrice(
                            data.product_price_original,
                            data.product_currency,
                            priceFloat
                        )}
                    </span>
                    <span className='card--price--percent'>
                        -
                        {Math.round(
                            ((data.product_price_original - data.product_price) * 100) /
                                data.product_price_original
                        )}
                        %
                    </span>
                </Title>
            )}
            <Title
                ellipsis={{
                    rows: 1,
                }}
                className='title title--center title--h2 card--title'
            >
                {formattedPrice(data.product_price, data.product_currency, priceFloat)}
            </Title>
        </Card>
    )
}

export default CardItem
