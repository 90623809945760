import React from 'react'
import { useAppSelector } from 'src/store'
import { resizeImage } from 'src/utils/image'

export default function MoreLook(props: { onLookSelect?: (look: Models.ModelProduct) => void }) {
    const models = useAppSelector((state) => state.model.all.items)

    return (
        <div className='more-look--container'>
            {models.map((model) => (
                <div
                    key={model.model_id + model.identity_id}
                    className='more-look--image-container'
                >
                    <img
                        src={resizeImage(model.image_url, { width: 800 })}
                        className='more-look--image'
                        onClick={() => props.onLookSelect(model)}
                    />
                </div>
            ))}
        </div>
    )
}
