import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

export default function Error() {
    const error = useSelector((state: State.Root) => state.error)
    const { t } = useCustomTranslation()

    return (
        <div className='App'>
            <div className='error--container'>
                <div className='error--content-container'>
                    <CloseCircleOutlined style={{ fontSize: 32 }} />
                    <div className='error--title'>{t('error.oops')}</div>
                    <p>{t('error.fatal')}</p>
                    <p>
                        {t('error.error')}
                        {error.message}
                    </p>
                </div>
            </div>
        </div>
    )
}
