import React, { useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import ImageSmooth from '../ImageSmooth'
import { Button, Col, Row, Typography } from 'antd'
import useCustomTranslation from 'src/utils/translation'
import { useAppSelector } from 'src/store'
import { addGarmentSizeToCart, getGarmentOptionSizes, getGarmentSizeLabel } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import CustomSpinner from '../CustomSpinner'
import FilterSelect from '../filter'

const { Title, Paragraph } = Typography

interface CardCartProps {
    garment: Models.Garment
    value: any
    onChange(e: any, name: string): void
    ratio: number
    cardFocusFunc?: any
    hideDescription?: boolean
}

const CardCart: React.FunctionComponent<CardCartProps> = (props) => {
    const { t } = useCustomTranslation()
    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const descriptionCatalog = useAppSelector(
        (state) => state.profile?.company?.description_catalog
    )
    const cartUrl = useAppSelector((state) => state.profile?.company?.external_cart_url)
    const useClipping = useAppSelector((state) => state.profile?.company?.use_clipping)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const sizeSelectRef = useRef(null)
    const { garment, value, ratio, cardFocusFunc, onChange, hideDescription } = props

    const optionSize = getGarmentOptionSizes(garment)

    const imageUrl =
        useClipping && garment.image_clipping_url ? garment.image_clipping_url : garment.image_url

    useEffect(() => {
        if (cardFocusFunc) {
            cardFocusFunc.current = () => {
                if (!value) {
                    return sizeSelectRef.current?.focus()
                }
            }
        }
        setGoToCart(false)
        // eslint-disable-next-line
    }, [value])

    const handleChange = (newValue, name) => {
        if (newValue) {
            trackEvent(
                'Size Selected',
                [
                    garment,
                    {
                        item_size_selected: newValue,
                        item_size_selected_label: getGarmentSizeLabel(newValue, optionSize),
                    },
                ],
                'Outfit Detail'
            )
        }
        onChange(newValue, name)
    }

    const handleAddToCart = () => {
        if (!value) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                garment,
                {
                    item_size_selected: value,
                    item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                },
            ],
            'Outfit Detail'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(garment, value, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        garment,
                        {
                            item_size_selected: value,
                            item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                        },
                    ],
                    'Outfit Detail'
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [garment], 'Outfit Detail')
        window.open(cartUrl)
    }

    return (
        <Row className='card-cart card-cart--container override_card_container'>
            <Col xs={{ span: 24 }} xl={{ span: 8 }} className='card-cart--col'>
                <div className='card-cart--image'>
                    <ImageSmooth
                        src={resizeImage(imageUrl, { width: 800 })}
                        ratio={ratio}
                        lazyload={false}
                    />
                </div>
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 16 }} className='card-cart--content'>
                <Row justify='space-between' gutter={16} wrap={false}>
                    <Col>
                        {garment.product_brand && (
                            <Paragraph
                                ellipsis={{
                                    rows: 2,
                                }}
                            >
                                {parse(garment.product_brand)}
                            </Paragraph>
                        )}
                        <Title
                            ellipsis={{
                                rows: descriptionCatalog === false ? 2 : 1,
                            }}
                            className='title title--h3 card-cart--title'
                        >
                            {parse(garment.product_name)}
                        </Title>
                        {descriptionCatalog !== false && !hideDescription && (
                            <Paragraph
                                ellipsis={{
                                    rows: 2,
                                }}
                            >
                                {parse(garment.product_description)}
                            </Paragraph>
                        )}
                    </Col>
                </Row>
                <Col>
                    {!!garment.product_price_original &&
                        garment.product_price_original > garment.product_price && (
                            <Title
                                ellipsis={{
                                    rows: 1,
                                }}
                                className='title card-cart--price--promotion'
                            >
                                <span className='card-cart--price--original'>
                                    {formattedPrice(
                                        garment.product_price_original,
                                        garment.product_currency,
                                        priceFloat
                                    )}
                                </span>
                                <span className='card-cart--price--percent'>
                                    -
                                    {Math.round(
                                        ((garment.product_price_original - garment.product_price) *
                                            100) /
                                            garment.product_price_original
                                    )}
                                    %
                                </span>
                            </Title>
                        )}
                    <Title
                        ellipsis={{
                            rows: 1,
                        }}
                        className='title title--h3'
                    >
                        {formattedPrice(
                            garment.product_price,
                            garment.product_currency,
                            priceFloat
                        )}
                    </Title>
                </Col>
                <div className='button--underlined'>{t(`product.see_product`)}</div>

                <div className='card-cart--action-container'>
                    <FilterSelect
                        ref={sizeSelectRef}
                        name='size'
                        onChange={handleChange}
                        value={value}
                        options={optionSize}
                        translation={false}
                        placeholder={t('product.please_choose')}
                        customStyle={{ flex: 1 }}
                    />
                    <div className='card-cart--button-container'>
                        {goToCart && cartUrl ? (
                            <Button
                                className='button button--dark button--full-width'
                                onClick={handleGoToCart}
                            >
                                {t('product.go_to_cart')}
                            </Button>
                        ) : (
                            <Button
                                className='button button--dark button--full-width'
                                disabled={addingToCart}
                                onClick={handleAddToCart}
                            >
                                {addingToCart ? <CustomSpinner /> : t('product.add_to_cart')}
                            </Button>
                        )}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default CardCart
