import React from 'react'
import { useAppSelector } from 'src/store'
import ImageSmooth from './ImageSmooth'
import Loader from './Loader'

export default function LookImage() {
    const company = useAppSelector((state: State.Root) => state.profile?.company)
    const look = useAppSelector((state) => state.look.current)
    const lookLoading = useAppSelector((state) => state.look.loading)
    const lookIndex = useAppSelector((state) => state.look.index)
    const lookImgRatio = company?.look_image_ratio
    return (
        <div className='look-image--container'>
            {look && (
                <>
                    {lookLoading === 0 ? (
                        <ImageSmooth
                            src={look.image_urls[lookIndex]}
                            className='look-image--image'
                            ratio={lookImgRatio}
                            loader={true}
                        />
                    ) : (
                        <Loader />
                    )}
                </>
            )}
        </div>
    )
}
