export const resizeImage = (src: string, params: any) => {
    // ---- Verification ----
    if (!src) {
        return src
    }

    // ---- Exception en dur ----
    const match = src.match(
        /^https?:\/\/storage\.googleapis\.com\/(demo-development-frameml)\/(.+)$/
    )
    if (match) {
        return resizeImage(
            `http://dev.images.veesual.ai/image?bucket=${encodeURIComponent(
                match[1]
            )}&img=${encodeURIComponent(match[2])}`,
            params
        )
    }

    // ---- Traitement de l'image ----
    let finalSrc = src
    if (src.match(/^https?:\/\/(dev\.)?images\.veesual\.ai\/image\?/)) {
        if (typeof params === 'object') {
            if (params.width && !finalSrc.match(`&w=${params.width}(&|$)`)) {
                finalSrc += `&w=${params.width}`
            }
            if (params.height && !finalSrc.match(`&h=${params.height}(&|$)`)) {
                finalSrc += `&h=${params.height}`
            }
        } else if (
            (typeof params === 'number' || typeof params === 'string') &&
            !finalSrc.match(`&w=${params}(&|$)`)
        ) {
            finalSrc += `&w=${params}`
        }
    }
    return finalSrc
}
