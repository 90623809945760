import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function useCustomTranslation() {
    const { t } = useTranslation()
    const internal = useSelector((state: State.Root) => state.profile?.company?.internal)

    function translateWithOverride(label: string, option?: { [key: string]: string }) {
        if (internal) {
            return t([`${internal}.${label}`, label], option)
        }
        return t(label, option)
    }

    return { t: translateWithOverride }
}
