import { HANDLE_FILTERS_MODEL, HANDLE_SHOW_FILTERS } from '../actions'

export const initialState: State.Filters = {
    model: {
        gender: 'male',
        minHeight: 151,
        maxHeight: 189,
        size: [],
        skinColor: '',
        hairColor: '',
    },
    showFilters: false,
}

const FiltersReducers = (
    state: State.Filters = initialState,
    action: Types.Action
): State.Filters => {
    switch (action.type) {
        case HANDLE_FILTERS_MODEL:
            return {
                ...state,
                model: action.payload,
            }
        case HANDLE_SHOW_FILTERS:
            return {
                ...state,
                showFilters: action.payload,
            }
        default:
            return state
    }
}

export default FiltersReducers
