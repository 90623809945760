import { HANDLE_START_LOADER, HANDLE_COMPLETE_LOADER } from '../actions'

export const initialState: State.Loader = {
    isLoading: false,
    isComplete: false,
}

const LoaderReducers = (state: State.Loader = initialState, action: Types.Action): State.Loader => {
    switch (action.type) {
        case HANDLE_START_LOADER:
            return {
                ...state,
                isLoading: !state.isLoading,
                isComplete: false,
            }
        case HANDLE_COMPLETE_LOADER:
            return {
                ...state,
                isComplete: true,
            }
        default:
            return state
    }
}

export default LoaderReducers
