import { getQueryValue } from 'src/utils/query'
import { FETCH_COMPANY_PROFILE, SET_PARENT_HEIGHT, SET_HEADER_HIDDEN } from '../actions'

export const initialState: State.Profile = {
    company: null,
    parentHeight: getQueryValue('parentHeight'),
    headerHidden: false,
    seed: new Date().getTime(),
}

const ProfileReducers = (
    state: State.Profile = initialState,
    action: Types.Action
): State.Profile => {
    switch (action.type) {
        case FETCH_COMPANY_PROFILE:
            return {
                ...state,
                company: action.payload,
            }
        case SET_PARENT_HEIGHT:
            return {
                ...state,
                parentHeight: action.payload,
            }
        case SET_HEADER_HIDDEN:
            return {
                ...state,
                headerHidden: action.payload,
            }
        default:
            return state
    }
}

export default ProfileReducers
