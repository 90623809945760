import { Dispatch } from 'redux'
import { GetGarmentDetailService } from '../../services/garment'

import {
    SET_QUERY_GARMENT_TYPE,
    FETCH_ALL_GARMENTS,
    FETCH_TYPE_GARMENT,
    FETCH_GARMENT_DETAIL,
    SET_CART_ADDED,
} from './index'

export const SetQueryGarmentTypeAction: Types.ActionFactory<string> = (payload) => ({
    type: SET_QUERY_GARMENT_TYPE,
    payload,
})

export const FetchAllGarmentAction: Types.ActionFactory<API.Resp.GetGarments> = (payload) => ({
    type: FETCH_ALL_GARMENTS,
    payload,
})

export const FetchTypeGarmentAction: Types.ActionFactory<string> = (payload) => ({
    type: FETCH_TYPE_GARMENT,
    payload,
})

export const FetchGarmentDetailAction: Types.ActionFactory<API.Resp.GetGarmentDetail> = (
    payload
) => ({
    type: FETCH_GARMENT_DETAIL,
    payload,
})

export const SetCartAddedAction: Types.ActionFactory<string[]> = (payload) => ({
    type: SET_CART_ADDED,
    payload,
})

export const SetTypesFromQueryCall = (type: string) => async (dispatch: Dispatch<Types.Action>) => {
    dispatch(SetQueryGarmentTypeAction(type))
    dispatch(FetchTypeGarmentAction(type))
}

export const GetGarmentDetailCall = (id: string) => async (dispatch: Dispatch<Types.Action>) => {
    const res: any = await GetGarmentDetailService({ id })
    if ([200, 201, 204].includes(res.status)) {
        dispatch(FetchGarmentDetailAction(res.data))
        return true
    }
    return false
}
