import ApiInstance from './index'

import { getQueryDefaultParams, getQueryValue } from 'src/utils/query'

const PROFILES_ENDPOINT = `/config`

// GET A PROFILE
const GetCompanyProfileService = async (): Promise<API.Resp.GetCompanyProfile> => {
    try {
        return await ApiInstance.get(`${PROFILES_ENDPOINT}`, {
            params: Object.assign(getQueryDefaultParams(), { origin: getQueryValue('origin') }),
        })
    } catch (error) {
        return error.response
    }
}

export { GetCompanyProfileService }
